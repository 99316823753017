<template>
    <div class="flex border-b border-gray-200 dark:border-gray-700 pb-6">
      <div class="grid grid-cols-12 w-full">

        <div class="col-span-12 sm:col-span-6 lg:col-span-4">
          <item-text title="Prece" :text="item.name" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <item-text title="Kategorija" :text="item.category.name" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <item-text title="Preces kods" :text="item.sku" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
          <item-text title="Noliktavas atlikums" :text="item.quantity_in_selected_warehouse" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <div class="w-32">
            <Input type="number" placeholder="Skaits" v-model="form.quantity" :errors="errors[`quantity-${item.id}`]" @change="setDifference" />
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
          <template v-if="difference && item.difference">
            <item-text title="Starpība" :text="item.difference" />
          </template>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
          <template v-if="showAutocomplete && form.difference">
            <Select v-model="form.warehouse" :errors="errors[`warehouse-${item.id}`]" :items="warehouses" placeholder="Noliktava" @change="changeWarehouse" />
          </template>
        </div>
      </div>

      <div class="ml-auto">
        <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
          <Button icon="close" @click="$emit('remove-product', item)" />
        </p>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import ItemText from "@/components/Components/ItemText";
import Input from "@/components/Components/Input";
import Select from "@/components/Components/Select";

export default {
  name: "SingleStockBalanceItemForm",
  props: {
    item: {},
    showAutocomplete: {
      type: Boolean,
      default: false
    },
    difference: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ItemText,
    Input,
    Select,
  },
  data: () => ({
    form: {},
    warehouse: null,
  }),
  mounted() {
    this.form = this.item
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      warehouses: "warehousesList",
      errors: "errors"
    }),
  },
  emits: ['item'],
  methods: {
    setDifference() {
      this.form.difference = +this.form.quantity - +this.form.quantity_in_selected_warehouse
      this.$emit("item", this.form)
    },
    changeWarehouse(value) {
      this.warehouse = value;
      this.form.move_to_warehouse = value;
    }
  }
}
</script>

<style>

</style>